import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import ImageSliderHook from "../hooks/ImageSilderHook";
import DropDownInput from "../components/DropDownInput";
import { validatePhoneNumber } from "../validations";
import { DOG_BREEDS, CAT_BREEDS } from "../utils";
import { getTransactionUrl } from "../actions/orderActions";
import useComponentVisible from "../hooks/useComponentVisible";

const OrderQRCorall = ({
  modalRef,
  setIsVisable,
  isForFriend = false,
  getTransactionUrl,
}) => {
  const images = [
    "/img/5.png",
    "/img/5.png",
    "/img/5.png",
    "/img/5.png",
    "/img/5.png",
  ];
  const [previewImage, setPreviewImage] = useState();
  const {
    currentImageIndex,
    setCurrentImageIndex,
    handleNextImage,
    handlePreviousImage,
  } = ImageSliderHook(images, 0);
  const [
    collarDescriptionRef,
    isCollarDescriptionVisalbe,
    setIsCollarDescriptionVisable,
  ] = useComponentVisible(false);
  const [collarSizeFitRef, isCollarSizeFitVisalbe, setIsCollarSizeFitVisable] =
    useComponentVisible(false);
  const [petProfileRef, isPetProfileVisalbe, setIsPetProfileVisable] =
    useComponentVisible(false);
  const [tagFeaturesRef, isTagFeaturesVisalbe, setIsTagFeaturesVisable] =
    useComponentVisible(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const OrderQrCorallSchema = yup.object().shape({
    with_collar: yup.string().required(),
    color: yup.string().required(),
    quantity: yup.number().required(),
    size: yup
      .number()
      .test(
        "not-required-if-for-GPS-tracker",
        "required_field",
        function (value) {
          const with_collar = this.resolve(yup.ref("with_collar"));
          if (with_collar !== "GPS tracker") {
            return yup.number().required().isValidSync(value);
          }
          return true;
        }
      ),
    full_name: yup.string().required(),
    phone_number: yup
      .number()
      .required()
      .typeError("phone_number_type_error")
      .test({
        name: "phone_number",
        test: validatePhoneNumber,
      }),
    city: yup.string().required(),
    address: yup.string().required(),
    pet_type: yup.string().required(),
    pet_name: yup.string().required(),
    pet_breed: yup.string().required(),
    gender: yup.string(),
    pet_color: yup.string(),
    is_vaccinated: yup.boolean(),
    comment: yup.string(),
    pet_image: yup.mixed(),
  });

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onSubmit", resolver: yupResolver(OrderQrCorallSchema) });
  const renderImages = () => {
    return images.map((image, index) => {
      return (
        <div className="img-box" onClick={() => setCurrentImageIndex(index)}>
          <img src={image} alt="" />
        </div>
      );
    });
  };

  const getBreed = () => {
    const breeds = { dog: DOG_BREEDS, cat: CAT_BREEDS, bird: ["qwe", "qwe"] };
    const petType = watch("pet_type", []);
    if (["dog", "cat", "bird"].includes(petType)) {
      return breeds[petType];
    }
    return [];
  };

  const renderPetIMG = () => {
    if (previewImage) {
      return <img src={previewImage} alt="pet photo" />;
    }
  };

  const calculatePrice = () => {
    const qrTagPrice = 55;
    const gpsTagPrice = 205;
    const with_collar = watch("with_collar", null);
    const quantity = watch("quantity", null);
    if (with_collar === "GPS tracker" && quantity !== null && quantity > 0) {
      return gpsTagPrice * quantity;
    }
    if (
      with_collar === "metal tag" ||
      (with_collar === "silicone tag" && quantity !== null && quantity > 0)
    ) {
      return qrTagPrice * quantity;
    }
    return 0;
  };

  const renderPetInfoFields = () => {
    const with_collar = watch("with_collar", null);
    const quantity = watch("quantity", null);
    if (
      (with_collar === "metal tag" || with_collar === "silicone tag") &&
      quantity !== null &&
      quantity > 1
    ) {
      return null;
    } else {
      return (
        <div className="pets-info-for-qr">
          <div className="pet-photo-container">
            <div
              className="pet-photo"
              for="files"
              onClick={() =>
                document.querySelector("#profile_pic_upload").click()
              }
            >
              {renderPetIMG()}
              <input
                id="profile_pic_upload"
                type="file"
                placeholder="atvite"
                style={{ display: "none" }}
                onChange={(e) => {
                  const files = e.target.files;
                  setValue("pet_image", files);
                  setPreviewImage(URL.createObjectURL(files[0]));
                }}
              />
            </div>
          </div>
          <DropDownInput
            name="animal"
            fieldName="pet_type"
            options={["dog", "cat", "bird"]}
            handleOptionClick={setValue}
          />

          <input
            className="pet-name"
            placeholder={t("pet name")}
            {...register("pet_name")}
          />

          <DropDownInput
            name="pet_breed"
            fieldName="pet_breed"
            options={getBreed()}
            handleOptionClick={setValue}
            search={true}
          />

          <DropDownInput
            name="gender"
            fieldName="gender"
            options={["male", "female"]}
            handleOptionClick={setValue}
          />

          <input
            className="pet-color-field"
            placeholder={t("pet color")}
            {...register("pet_color")}
          />

          <DropDownInput
            name="vaccinated"
            fieldName="vaccinated"
            options={["yes (for vaccinated)", "no"]}
            handleOptionClick={setValue}
          />

          {renderCommentForFinder()}
        </div>
      );
    }
  };

  const renderColor = () => {
    const with_collar = watch("with_collar", null);
    if (with_collar === "GPS tracker") {
      return null;
    } else {
      return (
        <div className="select-color-container">
          <div className="select-color">
            <p className="select-color-text">{t("select color")}</p>
            <div className="color-button-container">
              <button
                onClick={() => setValue("color", "black")}
                className={watch("color") === "black" ? "active" : ""}
                data-title={t("black")}
              >
                <span>{t("black")}</span>
              </button>
              <button
                onClick={() => setValue("color", "orange")}
                className={watch("color") === "orange" ? "active" : ""}
                data-title={t("orange")}
              >
                <span>{t("orange")}</span>
              </button>
              <button
                onClick={() => setValue("color", "cream")}
                className={watch("color") === "cream" ? "active" : ""}
                data-title={t("cream")}
              >
                <span>{t("cream")}</span>
              </button>
              <button
                onClick={() => setValue("color", "pink")}
                className={watch("color") === "pink" ? "active" : ""}
                data-title={t("pink")}
              >
                <span>{t("pink")}</span>
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderSizeFields = () => {
    const with_collar = watch("with_collar", null);
    if (with_collar === "GPS tracker") {
      return null;
    } else {
      return (
        <div className="qr-tag-size">
          <div
            className={watch("size") === 22 ? "active" : ""}
            onClick={() => setValue("size", 22)}
          >
            {t("small")} - 25 {t("mm")}
          </div>
          <div
            className={watch("size") === 30 ? "active" : ""}
            onClick={() => setValue("size", 30)}
          >
            {t("medium")} - 30 {t("mm")}
          </div>
          <div
            className={watch("size") === 35 ? "active" : ""}
            onClick={() => setValue("size", 35)}
          >
            {t("big")} - 35 {t("mm")}
          </div>
        </div>
      );
    }
  };

  const renderCommentForFinder = () => {
    const with_collar = watch("with_collar", null);
    if (with_collar === "GPS tracker") {
      return null;
    } else {
      return (
        <textarea
          className="comment-for-qr-tag"
          placeholder={t("comment for finder")}
          maxlength="250"
          {...register("comment")}
        ></textarea>
      );
    }
  };

  return (
    <div className="order-collars-container">
      <div className="collar-order-modal-container">
        <div className="slideshow-and-imglist-and-description">
          <div className="slideshow-and-imglist">
            <div class="collar-manual-slideshow-container">
              <img src={images[currentImageIndex]} alt="" />

              <a class="prev" onClick={handlePreviousImage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M14.29 5.707a1 1 0 00-1.415 0L7.988 10.6a2 2 0 000 2.828l4.89 4.89a1 1 0 001.415-1.414l-4.186-4.185a1 1 0 010-1.415l4.182-4.182a1 1 0 000-1.414z"
                  ></path>
                </svg>
              </a>
              <a class="next" onClick={handleNextImage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M9.71 18.293a1 1 0 001.415 0l4.887-4.892a2 2 0 000-2.828l-4.89-4.89a1 1 0 00-1.415 1.414l4.186 4.185a1 1 0 010 1.415L9.71 16.879a1 1 0 000 1.414z"
                  ></path>
                </svg>
              </a>

              <div className="imglist">{renderImages()}</div>
            </div>
          </div>

          <div className="collar-description-block-container">
            <div
              className={
                "inside-block-1 " + (isCollarDescriptionVisalbe ? "active" : "")
              }
              onClick={() =>
                setIsCollarDescriptionVisable(!isCollarDescriptionVisalbe)
              }
              // ref={collarDescriptionRef}
            >
              <p className="title">
                {t("how it works text1")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                  version="1.1"
                  viewBox="0 0 100 100"
                >
                  <path d="M78.466 35.559L50.15 63.633 22.078 35.317a2 2 0 00-2.84 2.815L48.432 67.58a1.993 1.993 0 001.727.568c.054.008.106.021.16.022a1.991 1.991 0 001.515-.576l29.447-29.196a1.999 1.999 0 10-2.815-2.839z"></path>
                </svg>
              </p>

              <p className="tag-feature-list">{t("how it works text")}</p>
              <p className="tag-feature-list">{t("how it works text2")}</p>
              <p className="tag-feature-list under">
                - {t("how it works text3")}
              </p>
              <p className="tag-feature-list under">
                - {t("how it works text4")}
              </p>
            </div>

            <div
              className={
                "inside-block-2 " + (isPetProfileVisalbe ? "active" : "")
              }
              onClick={() => setIsPetProfileVisable(!isPetProfileVisalbe)}
              // ref={petProfileRef}
            >
              <p className="title">
                {t("pet profile")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                  version="1.1"
                  viewBox="0 0 100 100"
                >
                  <path d="M78.466 35.559L50.15 63.633 22.078 35.317a2 2 0 00-2.84 2.815L48.432 67.58a1.993 1.993 0 001.727.568c.054.008.106.021.16.022a1.991 1.991 0 001.515-.576l29.447-29.196a1.999 1.999 0 10-2.815-2.839z"></path>
                </svg>
              </p>

              <p className="tag-feature-list">{t("pet profile text")}</p>
              <p className="tag-feature-list">{t("pet profile text2")}</p>
            </div>

            <div
              className={
                "inside-block-3 " + (isTagFeaturesVisalbe ? "active" : "")
              }
              onClick={() => setIsTagFeaturesVisable(!isTagFeaturesVisalbe)}
              // ref={tagFeaturesRef}
            >
              <p className="title">
                {t("tag features")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                  version="1.1"
                  viewBox="0 0 100 100"
                >
                  <path d="M78.466 35.559L50.15 63.633 22.078 35.317a2 2 0 00-2.84 2.815L48.432 67.58a1.993 1.993 0 001.727.568c.054.008.106.021.16.022a1.991 1.991 0 001.515-.576l29.447-29.196a1.999 1.999 0 10-2.815-2.839z"></path>
                </svg>
              </p>

              <ul>
                <li>{t("waterproof")}</li>

                <li>{t("scratch, bite, and fade resistant")}</li>

                <li>{t("light weight and jingle free")}</li>

                <li>{t("no batteries needed (tag will last you decades!)")}</li>

                <li>{t("compatible with all smartphones")}</li>

                <li>{t("no monthly fee")}</li>

                <li>{t("can be used anywhere and anytime")}</li>
              </ul>
            </div>

            <div
              className={
                "inside-block-4 " + (isCollarSizeFitVisalbe ? "active" : "")
              }
              onClick={() => setIsCollarSizeFitVisable(!isCollarSizeFitVisalbe)}
              // ref={collarSizeFitRef}
            >
              <p className="title">
                {t("size and fit")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                  version="1.1"
                  viewBox="0 0 100 100"
                >
                  <path d="M78.466 35.559L50.15 63.633 22.078 35.317a2 2 0 00-2.84 2.815L48.432 67.58a1.993 1.993 0 001.727.568c.054.008.106.021.16.022a1.991 1.991 0 001.515-.576l29.447-29.196a1.999 1.999 0 10-2.815-2.839z"></path>
                </svg>
              </p>

              <p className="tag-feature-list">
                {t("small")} - 25 {t("mm")} &emsp; ({" "}
                {t("for cats and little dogs")} )
              </p>
              <p className="tag-feature-list">
                {t("medium")} - 30 {t("mm")} &emsp; ({" "}
                {t("for medium size dogs")} )
              </p>
              <p className="tag-feature-list">
                {t("big")} - 35 {t("mm")} &emsp; ( {t("for big size dogs")} )
              </p>
            </div>
          </div>
        </div>

        <div className="collar-header-and-options-container">
          <div className="collar-header">
            <p>{t("qr tag with collar")}</p>
            <Link onClick={goBack} className="close_login">
              <span>{t("close modal")}</span>
              &times;
            </Link>
          </div>

          <div className="separate-container">
            <div className="qr-without-collar">
              <DropDownInput
                name="choose a collar"
                fieldName="with_collar"
                options={["metal tag", "GPS tracker", "GPS live locator"]}
                handleOptionClick={setValue}
                customClassNameWrapper="custom-wrapper"
                customClassNameSelectBtn="custom-select-btn"
                customClassNameSelecMenuDropDown="custom-drop-down"
              />
            </div>

            {renderColor()}

            {renderSizeFields()}

            <p className="qr-collar-price">
              <span className="quantity-container">
                <span>{t("quantity")}:</span>
                <DropDownInput
                  name="0"
                  fieldName="quantity"
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                  handleOptionClick={setValue}
                  customClassNameWrapper="custom-wrapper-quantity"
                  customClassNameSelectBtn="custom-select-btn-quantity"
                  customClassNameSelecMenuDropDown="custom-drop-down-quantity"
                />
              </span>
              {t("price")}:&emsp;{calculatePrice()} {t("gel")}
            </p>
          </div>

          <div className="warning">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="m12 1.5c-5.79844 0-10.5 4.70156-10.5 10.5 0 5.7984 4.70156 10.5 10.5 10.5 5.7984 0 10.5-4.7016 10.5-10.5 0-5.79844-4.7016-10.5-10.5-10.5zm.75 15.5625c0 .1031-.0844.1875-.1875.1875h-1.125c-.1031 0-.1875-.0844-.1875-.1875v-6.375c0-.1031.0844-.1875.1875-.1875h1.125c.1031 0 .1875.0844.1875.1875zm-.75-8.0625c-.2944-.00601-.5747-.12718-.7808-.3375-.206-.21032-.3215-.49305-.3215-.7875s.1155-.57718.3215-.7875c.2061-.21032.4864-.33149.7808-.3375.2944.00601.5747.12718.7808.3375.206.21032.3215.49305.3215.7875s-.1155.57718-.3215.7875c-.2061.21032-.4864.33149-.7808.3375z"></path>
            </svg>
            <p>{t("qr warning message")}</p>
          </div>

          <form
            onSubmit={handleSubmit((data) => {
              if (isForFriend) {
                data.for_friend = true;
              } else {
                data.for_friend = false;
              }
              if (data.with_collar === "GPS tracker") {
                getTransactionUrl(data, "gps-collar");
              } else {
                getTransactionUrl(data, "qr-collar");
              }
            })}
          >
            <div className="input-container">
              <div className="separate-container input-container">
                <h4>{t("owner")}</h4>
                <input
                  placeholder={t("full_name")}
                  {...register("full_name")}
                />
                <input
                  type="number"
                  placeholder={t("phone_number")}
                  {...register("phone_number")}
                />
                <input placeholder={t("city")} {...register("city")} />
                <input placeholder={t("address")} {...register("address")} />
              </div>

              {/* <hr className="" /> */}

              <div className="separate-container">
                <h4>{t("pet info")}</h4>

                {renderPetInfoFields()}

                <div className="qr-collar-rules">
                  <input type="checkbox" />
                  <p>
                    <Link to="/rules">{t("read")}</Link>{" "}
                    {t("and agree to the user agreement")}
                  </p>
                </div>

                <button type="submit" className="order-button">
                  {t("checkout")}
                </button>
              </div>
            </div>
          </form>

          <p className="shipping-title">{t("shipping to georgia")}</p>

          <div className="shipping">
            <div className="shipping-header-flex">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path d="M0 6v2h19v15h-6.156c-.446-1.719-1.992-3-3.844-3-1.852 0-3.398 1.281-3.844 3H4v-5H2v7h3.156c.446 1.719 1.992 3 3.844 3 1.852 0 3.398-1.281 3.844-3h8.312c.446 1.719 1.992 3 3.844 3 1.852 0 3.398-1.281 3.844-3H32v-8.156l-.063-.157-2-6L29.72 10H21V6zm1 4v2h9v-2zm20 2h7.281L30 17.125V23h-1.156c-.446-1.719-1.992-3-3.844-3-1.852 0-3.398 1.281-3.844 3H21zM2 14v2h6v-2zm7 8c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm16 0c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2z"></path>
              </svg>
              <p className="delivery">{t("delivery")}</p>
            </div>

            <div className="inside-container">
              <p className="delivery-prices">
                {t("tbilisi")} - {t("free")}
              </p>
              <p className="delivery-report">
                {t("call us")} - {t("591 - 607 - 607")}
              </p>
            </div>
          </div>

          <div className="shipping">
            <div className="shipping-header-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                stroke="#000"
                strokeWidth="20.48"
                className="not-fill"
                viewBox="-20.48 -20.48 1064.96 1064.96"
              >
                <g>
                  <path d="M488.006 999.898C218.982 999.898.106 781.022.106 512c0-269.022 218.876-487.898 487.9-487.898 156.624 0 304.81 76.03 396.386 203.364a8.016 8.016 0 01-1.828 11.17c-3.61 2.562-8.592 1.766-11.17-1.828C782.818 113.63 639.49 40.1 488.006 40.1 227.792 40.098 16.104 251.788 16.104 512c0 260.21 211.69 471.902 471.902 471.902 260.21 0 471.902-211.692 471.902-471.902a7.992 7.992 0 017.998-7.998 7.994 7.994 0 017.998 7.998c0 269.022-218.876 487.898-487.898 487.898z"></path>
                  <path d="M1015.896 567.988a7.976 7.976 0 01-5.656-2.344l-47.99-47.99a7.996 7.996 0 1111.31-11.31l47.99 47.992a7.994 7.994 0 010 11.308 7.96 7.96 0 01-5.654 2.344z"></path>
                  <path d="M919.916 567.988a7.994 7.994 0 01-5.656-13.652l47.99-47.992a7.996 7.996 0 1111.31 11.31l-47.99 47.99a7.96 7.96 0 01-5.654 2.344zm-191.96 167.966H280.048c-4.422 0-8-3.578-8-7.998V376.028a7.994 7.994 0 018-7.998h447.906c4.422 0 8 3.578 8 7.998v351.928a7.994 7.994 0 01-7.998 7.998zm-439.91-15.996h431.91V384.026h-431.91v335.932z"></path>
                  <path d="M727.956 384.026H280.048a8.002 8.002 0 01-7.39-4.936 8.018 8.018 0 011.734-8.716l63.988-63.986a7.992 7.992 0 015.656-2.344H663.97c2.124 0 4.154.844 5.654 2.344l63.988 63.986a8.02 8.02 0 011.734 8.716 8.002 8.002 0 01-7.39 4.936zM299.358 368.03h409.29l-47.99-47.99H347.346l-47.988 47.99z"></path>
                  <path d="M456.012 384.026a8.008 8.008 0 01-7.156-11.576l31.994-63.988c2-3.936 6.78-5.546 10.732-3.578a8.006 8.006 0 013.578 10.732l-31.994 63.986a8.014 8.014 0 01-7.154 4.424zm95.98 0a8.016 8.016 0 01-7.154-4.42l-31.994-63.986a8.006 8.006 0 013.578-10.732 7.992 7.992 0 0110.732 3.578l31.992 63.988a8.006 8.006 0 01-3.578 10.732 7.992 7.992 0 01-3.576.84z"></path>
                  <path d="M456.012 448.014a7.994 7.994 0 01-7.998-8v-63.988c0-4.42 3.578-7.998 7.998-7.998s7.998 3.578 7.998 7.998v63.988c0 4.422-3.578 8-7.998 8z"></path>
                  <path d="M551.992 448.014h-95.98c-4.42 0-7.998-3.578-7.998-8s3.578-7.998 7.998-7.998h95.98c4.42 0 7.998 3.578 7.998 7.998s-3.578 8-7.998 8z"></path>
                  <path d="M551.992 448.014a7.994 7.994 0 01-7.998-8v-63.988a7.994 7.994 0 017.998-7.998 7.994 7.994 0 017.998 7.998v63.988c0 4.422-3.578 8-7.998 8zm-95.98 7.998a7.994 7.994 0 01-7.998-7.998v-8c0-4.42 3.578-7.998 7.998-7.998s7.998 3.578 7.998 7.998v8a7.994 7.994 0 01-7.998 7.998zm31.994 0c-4.422 0-8-3.578-8-7.998v-8a7.994 7.994 0 018-7.998 7.994 7.994 0 017.998 7.998v8a7.994 7.994 0 01-7.998 7.998zm31.992 0a7.994 7.994 0 01-7.998-7.998v-8a7.994 7.994 0 017.998-7.998c4.422 0 8 3.578 8 7.998v8a7.994 7.994 0 01-8 7.998z"></path>
                  <path d="M551.992 456.012a7.994 7.994 0 01-7.998-7.998v-8a7.994 7.994 0 017.998-7.998 7.994 7.994 0 017.998 7.998v8a7.994 7.994 0 01-7.998 7.998zm143.97 247.948h-31.994a7.994 7.994 0 01-7.998-8v-31.992c0-4.422 3.578-8 7.998-8h31.994a7.994 7.994 0 017.998 8v31.992c0 4.422-3.578 8-7.998 8zm-23.996-15.998h15.996v-15.996h-15.996v15.996zm-39.992 15.998h-31.992a7.994 7.994 0 01-7.998-8v-31.992c0-4.422 3.576-8 7.998-8h31.992c4.422 0 8 3.578 8 8v31.992c0 4.422-3.578 8-8 8zm-23.994-15.998h15.996v-15.996H607.98v15.996zm-135.972-15.996H312.04a7.994 7.994 0 01-7.998-7.998c0-4.422 3.578-8 7.998-8h159.968a7.994 7.994 0 017.998 8 7.994 7.994 0 01-7.998 7.998zm-79.982 31.994h-79.984a7.994 7.994 0 01-7.998-8 7.994 7.994 0 017.998-7.998h79.984a7.994 7.994 0 017.998 7.998c0 4.422-3.578 8-7.998 8z"></path>
                </g>
              </svg>
              <p className="delivery">{t("return policy")}</p>
            </div>

            <div className="inside-container">
              <p className="delivery-prices">
                <Link to="/returnpolicy">{t("return policy")}</Link>
              </p>
              <p className="delivery-report">
                {t("call us")} - {t("591 - 607 - 607")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getTransactionUrl })(OrderQRCorall);
