import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ImageForPostCard from "./ImageForPostCard";
import PostCardImageContainer from "./PostCardImageContainer";
import { useTranslation } from "react-i18next";

import { updateSavedPostsIDs } from "../actions/postActions";
import { DELETE_SAVED_POST, SAVE_POST } from "../actions/types";
import { calculatePrice } from "../utils";

const PostCard = ({
  data,
  updateSavedPostsIDs,
  savedPosts,
  USD_RATE,
  userCurrency,
  price_with_deal,
}) => {
  const { t } = useTranslation();
  const renderSaveIcond = () => {
    if (savedPosts.includes(data.id)) {
      return (
        <svg
          className="save-button active"
          onClick={() =>
            updateSavedPostsIDs(data.id, "delete", DELETE_SAVED_POST)
          }
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
          </svg>
        </svg>
      );
    }
    return (
      <svg
        className="save-button"
        onClick={() => updateSavedPostsIDs(data.id, "post", SAVE_POST)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
        </svg>
      </svg>
    );
  };

  const renderDepartmentAndPrice = () => {
    if (data.department === "for_sale" && data.price_with_deal === true) {
      return <p className="card-1-price-with-deal">{t("price with deal")}</p>;
    } else if (
      data.department === "for_sale" &&
      data.price_with_deal === false
    ) {
      return (
        <p>
          {calculatePrice(
            data.price_in_GEL,
            data.price_in_USD,
            data.price_with_deal,
            userCurrency,
            USD_RATE
          )}{" "}
          {userCurrency === "GEL" ? "₾" : "$"}
        </p>
      );
    } else if (data.department === "adopt") {
      return <span className="adopt">{t("adopt")}</span>;
    }
  };

  const renderVaccineIcon = () => {
    if ((data.is_vaccinated = true)) {
      return (
        <span className="vaccine-icon-container green">{t("vaccinated")}</span>
      );
    } else if ((data.is_vaccinated = false)) {
      return (
        <span className="vaccine-icon-container red">
          {t("not vaccinated")}
        </span>
      );
    }
  };

  return (
    <div className="card">
      <div className="overlay-card-info-container">
        <div className="price-doc-vacc-container">
          <p className="overlay-price">{renderDepartmentAndPrice()}</p>
          <div className="overlay-doc-vacc-container">
            {
              data.documents ? (
                <>
                  <span title={t("with documents")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        d="M12.9503 22C15.595 22 16.9173 22 17.8667 21.2437C18.8161 20.4874 19.1189 19.1927 19.7247 16.6033L21.642 8.40697C21.9773 6.97363 22.1449 6.25696 21.8405 5.7379C21.2878 4.79529 19.8789 5.00001 18.9593 5.00001"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 9C2 5.70017 2 4.05025 3.02513 3.02513C4.05025 2 5.70017 2 9 2H12C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22H9C5.70017 22 4.05025 22 3.02513 20.9749C2 19.9497 2 18.2998 2 15V9Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M10.5 6C12.7091 6 14.5 7.79086 14.5 10C14.5 12.2091 12.7091 14 10.5 14M10.5 6C8.29086 6 6.5 7.79086 6.5 10C6.5 12.2091 8.29086 14 10.5 14M10.5 6C9.67157 6 9 7.79086 9 10C9 12.2091 9.67157 14 10.5 14M10.5 6C11.3284 6 12 7.79086 12 10C12 12.2091 11.3284 14 10.5 14"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M7 17L14 17"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>

                    {/* {t("with documents")} */}
                  </span>
                </>
              ) : null
              // <>
              //   <span className="documents not" title={t("without documents")}>
              //     {t("without documents")}
              //   </span>
              // </>
            }

            {
              data.is_vaccinated ? (
                <>
                  <span title={t("vaccinated")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        d="M17 2C17 2.50986 17 2.76479 17.0677 3.00236C17.1049 3.13286 17.157 3.25864 17.223 3.37723C17.3431 3.59309 17.5234 3.77336 17.8839 4.13388L19.8661 6.11612C20.2266 6.47664 20.4069 6.65691 20.6228 6.77701C20.7414 6.84298 20.8671 6.89509 20.9976 6.93228C21.2352 7 21.4901 7 22 7"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.6931 7L6.76525 11.9278C5.70048 12.9926 5.1681 13.525 5.0412 14.163C4.98627 14.4391 4.98627 14.7234 5.0412 14.9996C5.1681 15.6376 5.70049 16.17 6.76525 17.2348C7.83001 18.2995 8.3624 18.8319 9.00037 18.9588"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 6L17 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 18.6372L6.83054 19.4725C6.35987 19.8087 6.12454 19.9768 5.86646 19.9971C5.76637 20.005 5.66567 19.9967 5.56824 19.9724C5.31702 19.91 5.11252 19.7055 4.70352 19.2965C4.29453 18.8875 4.09003 18.683 4.02756 18.4318C4.00333 18.3343 3.995 18.2336 4.00288 18.1335C4.0232 17.8755 4.1913 17.6401 4.52749 17.1695L5.36282 16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.5 8L18.5 4M16 10.5L20 5.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.5 19.5L2 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.4 18L13.9806 18.5806C14.2702 18.8702 14.415 19.015 14.5912 19.007C14.7675 18.999 14.8986 18.8417 15.1608 18.527L16.6 16.8M19 18C19 20.2091 17.2091 22 15 22C12.7909 22 11 20.2091 11 18C11 15.7909 12.7909 14 15 14C17.2091 14 19 15.7909 19 18Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {/* {t("vaccinated")} */}
                  </span>
                </>
              ) : null
              // <>
              //   <span
              //     className="vaccine-icon-container not"
              //     title={t("not vaccinated")}
              //   >
              //     {t("not vaccinated")}
              //   </span>
              // </>
            }
          </div>
        </div>
      </div>

      <div className="slide-img-box-for-postcard">
        <Link to={`/post/${data.id}`}>
          <PostCardImageContainer
            data={data}
            ImageRenderer={ImageForPostCard}
          />
        </Link>
      </div>

      <div className="overlay-breed-and-save-container">
        <p className="overlay-breed-container">{t(data.breed)}</p>
        <div className="save-button-container">{renderSaveIcond()}</div>
      </div>

      <div className="card-info-container">
        <div className="breed">
          <Link className="breed-text" to={`/post/${data.id}`}>
            {t(data.breed)}
          </Link>
          <div className="save-button-container">{renderSaveIcond()}</div>
        </div>

        <div className="age-city">
          <a className="gender">{t(data.gender)}</a>
          <a className="age">
            {data.age} {t("month")}
          </a>
          {/* <a className="city">{t(data.city)}</a> */}
        </div>

        <div className="price">{renderDepartmentAndPrice()}</div>

        <hr />

        <div className="vaccine-documents">
          {
            data.documents ? (
              <>
                <span className="documents" title={t("with documents")}>
                  {t("with documents")}
                </span>
              </>
            ) : null
            // <>
            //   <span className="documents not" title={t("without documents")}>
            //     {t("without documents")}
            //   </span>
            // </>
          }

          {
            data.is_vaccinated ? (
              <>
                <span
                  className="vaccine-icon-container"
                  title={t("vaccinated")}
                >
                  {t("vaccinated")}
                </span>
              </>
            ) : null
            // <>
            //   <span
            //     className="vaccine-icon-container not"
            //     title={t("not vaccinated")}
            //   >
            //     {t("not vaccinated")}
            //   </span>
            // </>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    savedPosts: state.posts.savedPosts,
    userCurrency: state.userData.userCurrency,
    USD_RATE: state.userData.USD_RATE,
  };
};

export default connect(mapStateToProps, { updateSavedPostsIDs })(PostCard);
