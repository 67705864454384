import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  deleteQR,
  changeQRMarkedAsLost,
  updateQR,
} from "../actions/orderActions";
import useComponentVisible from "../hooks/useComponentVisible";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { validatePhoneNumber } from "../validations";

const QRCollarContainer = ({
  qr,
  deleteQR,
  changeQRMarkedAsLost,
  updateQR,
  isVisable,
}) => {
  const { t } = useTranslation();
  const [isQRCodeVisable, setIsQRCodeVisable] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [markedAsLost, setMarkedAsLost] = useState(qr.marked_as_lost);
  const [isQRCollarInfoVisable, setIsQRCollarInfoVisable] = useState(false);
  const [
    isQRCollarInstructionButtonVisable,
    setIsQRCollarInstructionButtonVisable,
  ] = useState(false);
  const [
    confirmationModalRef,
    isConfirmationVisable,
    setIsConfirmationVisable,
  ] = useComponentVisible(false);

  const UpdateQRCollarSchema = yup.object().shape({
    city: yup.string().required(),
    address: yup.string().required(),
    comment: yup.string().required(),
    phone_number: yup
      .number()
      .required()
      .typeError("phone_number_type_error")
      .test({
        name: "phone_number",
        test: validatePhoneNumber,
      }),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(UpdateQRCollarSchema),
  });
  useEffect(() => {
    reset(qr);
  }, [qr]);

  const renderImage = () => {
    if (isQRCodeVisable) {
      return <img className="qr-code-png" src={qr.qr_code} alt="" />;
    }
    return <img className="pet-img" src={qr.pet_image} alt="" />;
  };

  const renderQrImgBtn = () => {
    if (isQRCodeVisable) {
      return (
        <button
          className="active-stroke"
          onClick={() => setIsQRCodeVisable(!isQRCodeVisable)}
          name={t("unseen your qr")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <g stroke="#000" strokeWidth="1.5">
              <path d="M5.5 15.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293h1c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v1c0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293-1.414 0-2.121 0-2.56-.44-.44-.439-.44-1.146-.44-2.56zM5.5 8.5c0-1.414 0-2.121.44-2.56.439-.44 1.146-.44 2.56-.44.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v1c0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293h-1c-.943 0-1.414 0-1.707-.293C5.5 9.914 5.5 9.443 5.5 8.5zM13.5 15.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293h1c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707 0 1.414 0 2.121-.44 2.56-.439.44-1.146.44-2.56.44-.943 0-1.414 0-1.707-.293-.293-.293-.293-.764-.293-1.707v-1zM13.5 7.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293 1.414 0 2.121 0 2.56.44.44.439.44 1.146.44 2.56 0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293h-1c-.943 0-1.414 0-1.707-.293-.293-.293-.293-.764-.293-1.707v-1z"></path>
              <path
                strokeLinecap="round"
                d="M22 14c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22M10 22c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14M10 2C6.229 2 4.343 2 3.172 3.172 2 4.343 2 6.229 2 10M14 2c3.771 0 5.657 0 6.828 1.172C22 4.343 22 6.229 22 10"
              ></path>
            </g>
          </svg>
          {t("qr code")}
        </button>
      );
    }
    return (
      <button
        className="not-active-stroke"
        onClick={() => setIsQRCodeVisable(!isQRCodeVisable)}
        name={t("see your qr")}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <g stroke="#000" strokeWidth="1.5">
            <path d="M5.5 15.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293h1c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v1c0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293-1.414 0-2.121 0-2.56-.44-.44-.439-.44-1.146-.44-2.56zM5.5 8.5c0-1.414 0-2.121.44-2.56.439-.44 1.146-.44 2.56-.44.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v1c0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293h-1c-.943 0-1.414 0-1.707-.293C5.5 9.914 5.5 9.443 5.5 8.5zM13.5 15.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293h1c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707 0 1.414 0 2.121-.44 2.56-.439.44-1.146.44-2.56.44-.943 0-1.414 0-1.707-.293-.293-.293-.293-.764-.293-1.707v-1zM13.5 7.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293 1.414 0 2.121 0 2.56.44.44.439.44 1.146.44 2.56 0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293h-1c-.943 0-1.414 0-1.707-.293-.293-.293-.293-.764-.293-1.707v-1z"></path>
            <path
              strokeLinecap="round"
              d="M22 14c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22M10 22c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14M10 2C6.229 2 4.343 2 3.172 3.172 2 4.343 2 6.229 2 10M14 2c3.771 0 5.657 0 6.828 1.172C22 4.343 22 6.229 22 10"
            ></path>
          </g>
        </svg>
        {t("qr code")}
      </button>
    );
  };

  const renderMarkAsLost = () => {
    if (markedAsLost === false) {
      return (
        <button
          className="not-active"
          name={t("mark as lost")}
          onClick={() => {
            changeQRMarkedAsLost(qr.id);
            setMarkedAsLost(true);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19.778 4.222A11 11 0 1112 1a1 1 0 011 1v8.277a2 2 0 11-2 0V7.621a4.49 4.49 0 104.182 1.2A1 1 0 0116.6 7.4 6.505 6.505 0 1111 5.585v-2.53a9 9 0 107.364 2.581 1 1 0 111.414-1.414z"></path>
          </svg>
          {t("activation")}
        </button>
      );
    }
    return (
      <button
        className="active"
        name={t("unmark as lost")}
        onClick={() => {
          changeQRMarkedAsLost(qr.id);
          setMarkedAsLost(false);
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M19.778 4.222A11 11 0 1112 1a1 1 0 011 1v8.277a2 2 0 11-2 0V7.621a4.49 4.49 0 104.182 1.2A1 1 0 0116.6 7.4 6.505 6.505 0 1111 5.585v-2.53a9 9 0 107.364 2.581 1 1 0 111.414-1.414z"></path>
        </svg>
        {t("deactivation")}
      </button>
    );
  };

  const renderQRCollarInstructionButton = () => {
    if (isQRCollarInstructionButtonVisable) {
      return (
        <button
          className="collar-instruction-button active hide-instruction-btn"
          name={t("info")}
          onClick={() =>
            setIsQRCollarInstructionButtonVisable(
              !isQRCollarInstructionButtonVisable
            )
          }
        >
          <svg
            className="big-icon"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#292D32"
              d="M21.56 10.738l-1.35-1.58c-.25-.3-.46-.86-.46-1.26v-1.7c0-1.06-.87-1.93-1.93-1.93h-1.7c-.4 0-.97-.21-1.27-.46l-1.58-1.35c-.69-.59-1.82-.59-2.51 0l-1.6 1.35c-.3.25-.86.46-1.26.46H6.17c-1.06 0-1.93.87-1.93 1.93v1.7c0 .39-.2.95-.45 1.25l-1.35 1.59c-.58.7-.58 1.82 0 2.5l1.35 1.59c.25.29.45.86.45 1.25v1.71c0 1.06.87 1.93 1.93 1.93h1.74c.39 0 .96.21 1.26.46l1.58 1.35c.69.59 1.82.59 2.51 0l1.58-1.35c.3-.25.86-.46 1.26-.46h1.7c1.06 0 1.93-.87 1.93-1.93v-1.7c0-.4.21-.96.46-1.26l1.35-1.58c.61-.68.61-1.81.02-2.51zm-10.31-2.61c0-.41.34-.75.75-.75s.75.34.75.75v4.83c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-4.83zm.75 8.74c-.55 0-1-.45-1-1s.44-1 1-1c.55 0 1 .45 1 1s-.44 1-1 1z"
            ></path>
          </svg>
          <span>{t("instruction")}</span>
        </button>
      );
    }
    return (
      <button
        className="collar-instruction-button active hide-instruction-btn"
        name={t("info")}
        onClick={() =>
          setIsQRCollarInstructionButtonVisable(
            !isQRCollarInstructionButtonVisable
          )
        }
      >
        <svg
          className="big-icon"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#292D32"
            d="M21.56 10.738l-1.35-1.58c-.25-.3-.46-.86-.46-1.26v-1.7c0-1.06-.87-1.93-1.93-1.93h-1.7c-.4 0-.97-.21-1.27-.46l-1.58-1.35c-.69-.59-1.82-.59-2.51 0l-1.6 1.35c-.3.25-.86.46-1.26.46H6.17c-1.06 0-1.93.87-1.93 1.93v1.7c0 .39-.2.95-.45 1.25l-1.35 1.59c-.58.7-.58 1.82 0 2.5l1.35 1.59c.25.29.45.86.45 1.25v1.71c0 1.06.87 1.93 1.93 1.93h1.74c.39 0 .96.21 1.26.46l1.58 1.35c.69.59 1.82.59 2.51 0l1.58-1.35c.3-.25.86-.46 1.26-.46h1.7c1.06 0 1.93-.87 1.93-1.93v-1.7c0-.4.21-.96.46-1.26l1.35-1.58c.61-.68.61-1.81.02-2.51zm-10.31-2.61c0-.41.34-.75.75-.75s.75.34.75.75v4.83c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-4.83zm.75 8.74c-.55 0-1-.45-1-1s.44-1 1-1c.55 0 1 .45 1 1s-.44 1-1 1z"
          ></path>
        </svg>
        <span>{t("instruction")}</span>
      </button>
    );
  };

  const renderQRCollarInstruction = () => {
    if (isQRCollarInstructionButtonVisable) {
      return (
        <div className="modal-background">
          <div className="qr-collar-buttons-instruction-container">
            <span className="instruction-container-top-buttons">
              <button
                className="close-instruction"
                onClick={() =>
                  setIsQRCollarInstructionButtonVisable(
                    !isQRCollarInstructionButtonVisable
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M20.746 3.329a1 1 0 00-1.415 0l-7.294 7.294-7.294-7.294a1 1 0 10-1.414 1.414l7.294 7.294-7.294 7.294a1 1 0 001.414 1.415l7.294-7.295 7.294 7.295a1 1 0 001.415-1.415l-7.295-7.294 7.295-7.294a1 1 0 000-1.414z"
                  ></path>
                </svg>
                <span>{t("close modal")}</span>
              </button>
            </span>

            <div className="instruction-container">
              <span className="without-stroke">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M19.778 4.222A11 11 0 1112 1a1 1 0 011 1v8.277a2 2 0 11-2 0V7.621a4.49 4.49 0 104.182 1.2A1 1 0 0116.6 7.4 6.505 6.505 0 1111 5.585v-2.53a9 9 0 107.364 2.581 1 1 0 111.414-1.414z"></path>
                </svg>
              </span>
              <p>{t("mark_as_lost_text")}</p>
            </div>

            <div className="instruction-container">
              <span className="orange-fill">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <g stroke="#000" strokeWidth="1.5">
                    <path d="M5.5 15.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293h1c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v1c0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293-1.414 0-2.121 0-2.56-.44-.44-.439-.44-1.146-.44-2.56zM5.5 8.5c0-1.414 0-2.121.44-2.56.439-.44 1.146-.44 2.56-.44.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v1c0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293h-1c-.943 0-1.414 0-1.707-.293C5.5 9.914 5.5 9.443 5.5 8.5zM13.5 15.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293h1c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707 0 1.414 0 2.121-.44 2.56-.439.44-1.146.44-2.56.44-.943 0-1.414 0-1.707-.293-.293-.293-.293-.764-.293-1.707v-1zM13.5 7.5c0-.943 0-1.414.293-1.707.293-.293.764-.293 1.707-.293 1.414 0 2.121 0 2.56.44.44.439.44 1.146.44 2.56 0 .943 0 1.414-.293 1.707-.293.293-.764.293-1.707.293h-1c-.943 0-1.414 0-1.707-.293-.293-.293-.293-.764-.293-1.707v-1z"></path>
                    <path
                      strokeLinecap="round"
                      d="M22 14c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22M10 22c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14M10 2C6.229 2 4.343 2 3.172 3.172 2 4.343 2 6.229 2 10M14 2c3.771 0 5.657 0 6.828 1.172C22 4.343 22 6.229 22 10"
                    ></path>
                  </g>
                </svg>
              </span>
              <p>{t("qr_image_text")}</p>
            </div>

            <div className="instruction-container">
              <span className="orange-fill">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </span>
              <p>{t("pet_info_text")}</p>
            </div>

            <div className="instruction-container">
              <span className="none-stroke">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-3.5 0 19 19"
                  fill="red"
                >
                  <path d="M11.383 13.644A1.03 1.03 0 019.928 15.1L6 11.172 2.072 15.1a1.03 1.03 0 11-1.455-1.456l3.928-3.928L.617 5.79a1.03 1.03 0 111.455-1.456L6 8.261l3.928-3.928a1.03 1.03 0 011.455 1.456L7.455 9.716z"></path>
                </svg>
              </span>
              <p>{t("delete_text")}</p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderQRCollarInfoButton = () => {
    if (isQRCollarInfoVisable) {
      return (
        <button
          className="active-stroke"
          name={t("info")}
          onClick={() => setIsQRCollarInfoVisable(!isQRCollarInfoVisable)}
        >
          <svg
            className="big-icon"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      );
    }
    return (
      <button
        className="not-active-stroke"
        name={t("info")}
        onClick={() => setIsQRCollarInfoVisable(!isQRCollarInfoVisable)}
      >
        <svg
          className="big-icon"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </button>
    );
  };

  const renderQRCollarInfo = () => {
    // if (isQRCollarInfoVisable) {
    return (
      <>
        <div className="pet-info-outside-container" id="pet-info-border-radius">
          <form
            onSubmit={handleSubmit((data) => {
              updateQR(qr.id, data);
              setIsUpdateDisabled((prevData) => !prevData);
            })}
            noValidate
            autoComplete=""
          >
            <div className="pet-info-top-buttons-container">
              <div className="pet-info-top-buttons-inside-container">
                <button
                  className="not-active-stroke edit-button"
                  name={t("Edit")}
                  onClick={() => setIsUpdateDisabled((prevState) => !prevState)}
                  type="button"
                >
                  {isUpdateDisabled ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000"
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        >
                          <path d="M4 12a8 8 0 0114.93-4"></path>
                          <path
                            d="M20 12a8 8 0 01-14.93 4"
                            data-name="primary"
                          ></path>
                          <path d="M14 8L19 8 19 3" data-name="primary"></path>
                          <path d="M10 16L5 16 5 21" data-name="primary"></path>
                        </g>
                      </svg>
                      <span>{t("Edit")}</span>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          style={{ stroke: "none", fill: "grey" }}
                          fill="#0F0F0F"
                          d="M20.746 3.329a1 1 0 00-1.415 0l-7.294 7.294-7.294-7.294a1 1 0 10-1.414 1.414l7.294 7.294-7.294 7.294a1 1 0 001.414 1.415l7.294-7.295 7.294 7.295a1 1 0 001.415-1.415l-7.295-7.294 7.295-7.294a1 1 0 000-1.414z"
                        ></path>
                      </svg>
                      <span>{t("cancel")}</span>
                    </>
                  )}
                </button>
                {isUpdateDisabled === false ? (
                  <button className="update-post-btn" type="submit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 5L8 15l-5-4"
                      ></path>
                    </svg>
                    <span>{t("confirm")}</span>
                  </button>
                ) : null}
              </div>

              <button
                className="close-update-container"
                onClick={() => setIsQRCollarInfoVisable(!isQRCollarInfoVisable)}
              >
                <span>{t("close modal")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M20.746 3.329a1 1 0 00-1.415 0l-7.294 7.294-7.294-7.294a1 1 0 10-1.414 1.414l7.294 7.294-7.294 7.294a1 1 0 001.414 1.415l7.294-7.295 7.294 7.295a1 1 0 001.415-1.415l-7.295-7.294 7.295-7.294a1 1 0 000-1.414z"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="pet-info-container">
              <div className="pet-info-container-title">
                <span className="title-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <g
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    >
                      <path d="M17.5 21h-11A2.5 2.5 0 014 18.5c0-4.08 6-4 8-4s8-.08 8 4a2.5 2.5 0 01-2.5 2.5zM12 11a4 4 0 100-8 4 4 0 000 8z"></path>
                    </g>
                  </svg>
                </span>
                <span className="title-text">{t("owner")}</span>
              </div>

              <label>
                <p>{qr.full_name}</p>
              </label>
              <label>
                <input {...register("city")} disabled={isUpdateDisabled} />
              </label>
              <label>
                <input
                  {...register("phone_number")}
                  disabled={isUpdateDisabled}
                />
              </label>
              <label>
                <input {...register("address")} disabled={isUpdateDisabled} />
              </label>
            </div>

            <hr />

            <div className="pet-info-container">
              <div className="pet-info-container-title">
                <span className="title-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <g stroke="#000" strokeWidth="1.5">
                      <path d="M7.57 15.376c1.586-3.228 2.38-4.842 3.52-5.227.591-.199 1.229-.199 1.82 0 1.14.385 1.934 1.999 3.52 5.227l.878 1.79c.41.833.614 1.25.663 1.534.201 1.179-.67 2.265-1.846 2.3-.283.008-.725-.113-1.61-.356-.505-.138-.758-.207-1.01-.259a7.581 7.581 0 00-3.01 0c-.252.051-.505.12-1.01.26-.885.242-1.327.363-1.61.355-1.175-.035-2.047-1.121-1.846-2.3.048-.284.253-.7.663-1.535l.879-1.789zM6.145 5.527c.412 1.631 1.576 2.717 2.6 2.426 1.025-.292 1.522-1.85 1.11-3.48-.412-1.631-1.576-2.717-2.6-2.426-1.025.292-1.522 1.85-1.11 3.48zM17.855 5.527c-.412 1.631-1.576 2.717-2.6 2.426-1.025-.292-1.522-1.85-1.11-3.48.412-1.631 1.576-2.717 2.6-2.426 1.025.292 1.522 1.85 1.11 3.48zM2.202 12.297c.45 1.205 1.508 1.937 2.363 1.635.855-.302 1.183-1.524.733-2.73-.45-1.204-1.508-1.936-2.363-1.634-.855.302-1.183 1.524-.733 2.73zM21.798 12.297c-.45 1.205-1.508 1.937-2.363 1.635-.855-.302-1.183-1.524-.733-2.73.45-1.204 1.508-1.936 2.363-1.634.855.302 1.183 1.524.733 2.73z"></path>
                    </g>
                  </svg>
                </span>
                <span className="title-text">{t("pet info")}</span>
              </div>

              <label>
                <p>{t(qr.pet_type)}</p>
              </label>
              <label>
                <p>{t(qr.pet_breed)}</p>
              </label>
              <label>
                <p>{t(qr.gender)}</p>
              </label>
              <label>
                <p>{qr.pet_color}</p>
              </label>
            </div>

            <hr />

            <div className="pet-info-container max-height">
              <div className="pet-info-container-title">
                <span className="title-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fff"
                    viewBox="0 0 32 32"
                  >
                    <g>
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeWidth="1"
                      >
                        <g fill="#fff" transform="translate(-207 -257)">
                          <path d="M231 273a2 2 0 11.001-4.001A2 2 0 01231 273zm-8 0a2 2 0 11.001-4.001A2 2 0 01223 273zm-8 0a2 2 0 11.001-4.001A2 2 0 01215 273zm8-16c-8.836 0-16 6.269-16 14 0 4.419 2.345 8.354 6 10.919V289l7.009-4.253c.97.16 1.968.253 2.991.253 8.836 0 16-6.268 16-14 0-7.731-7.164-14-16-14z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="title-text">{t("comment")}</span>
              </div>

              <textarea
                // className="none"
                {...register("comment")}
                name=""
                disabled={isUpdateDisabled}
              ></textarea>
            </div>
          </form>
        </div>
      </>
    );
    // }
    return null;
  };

  return (
    <>
      <ConfirmationDialog
        modalRef={confirmationModalRef}
        isVisable={isConfirmationVisable}
        setIsVisable={setIsConfirmationVisable}
        onConfirm={() => {
          deleteQR(qr.id);
          setIsConfirmationVisable(false);
        }}
      />
      {renderQRCollarInstruction()}

      <div className="purchased-collars-card">
        <div className="pet-img-and-buttons">
          <div className="purchased-collars-card-pet-name">
            <span className="pet-name-inside-container">{t(qr.pet_name)}</span>
          </div>

          <div className="img-box">{renderImage()}</div>

          <div className="edit-delete-container">
            {renderMarkAsLost()}
            {renderQrImgBtn()}
            {/* {renderQRCollarInfoButton()} */}
            {renderQRCollarInstructionButton()}

            {/* <button
              name={t("Delete")}
              onClick={() => setIsConfirmationVisable(!isConfirmationVisable)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-3.5 0 19 19"
                fill="red"
              >
                <path d="M11.383 13.644A1.03 1.03 0 019.928 15.1L6 11.172 2.072 15.1a1.03 1.03 0 11-1.455-1.456l3.928-3.928L.617 5.79a1.03 1.03 0 111.455-1.456L6 8.261l3.928-3.928a1.03 1.03 0 011.455 1.456L7.455 9.716z"></path>
              </svg>
            </button> */}
          </div>
        </div>
        {renderQRCollarInfo()}
      </div>
    </>
  );
};

export default connect(null, { deleteQR, changeQRMarkedAsLost, updateQR })(
  QRCollarContainer
);
