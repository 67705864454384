import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useComponentVisible from "../hooks/useComponentVisible";

const Footrer = () => {
  const { t } = useTranslation();
  const [aboutCompanyRef, isAboutCompanyVisalbe, setIsAboutCompanyVisable] =
    useComponentVisible(false);
  const [qrCollarRef, isQrCollarVisalbe, setIsQrCollarVisable] =
    useComponentVisible(false);
  const [ourServiceRef, isOurServiceVisalbe, setIsOurServiceVisable] =
    useComponentVisible(false);

  return (
    <>
      <a className="scroll-up" href="#headnav">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -4.5 20 20">
          <g>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g fill="#000" transform="translate(-260 -6684)">
                <g transform="translate(56 160)">
                  <path d="M223.708 6534.634c.39-.405.39-1.06 0-1.464l-8.264-8.563a1.95 1.95 0 00-2.827 0l-8.325 8.625c-.385.4-.39 1.048-.01 1.454a.976.976 0 001.425.01l7.617-7.893a.975.975 0 011.414 0l7.557 7.83a.974.974 0 001.413 0"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </a>
      <div className="footer-parent-container">
        <div className="footer-parent-inside-container">
          <footer className="footer">
            <ul>
              <li>
                <span className="title">{t("company")}</span>
              </li>
              {/* <li>
                <Link to="/aboutus">{t("about_us")}</Link>
              </li> */}
              <li>
                <Link to="/contact">{t("contact")}</Link>
              </li>

              <li>
                <Link to="/rules">{t("site rules (footer)")}</Link>
              </li>
              <li>
                <Link to="/returnpolicy">{t("return policy")}</Link>
              </li>
              <li>
                <Link to="/privacypolicy">{t("privacy policy")}</Link>
              </li>
              <li>
                <Link to="">
                  {t("download")} {t("app")}
                </Link>
              </li>
            </ul>

            <ul>
              <li>
                <span className="title">{t("qrcollar")}</span>
              </li>
              <li>
                <Link to="/order/qrcollar">{t("buy now (footer)")}</Link>
              </li>
              <li>
                <Link to="/qrcollar">{t("how it works text1")}</Link>
              </li>
              <li>
                <Link to="/qrcollar">{t("video instruction")}</Link>
              </li>
            </ul>

            <ul>
              <li>
                <span className="title">{t("why our service")}</span>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("you have control panel")}
                </p>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("pet's profile update")}
                </p>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("activation")} / {t("deactivation")}
                </p>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("no monthly fee")}
                </p>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("no batteries needed")}
                </p>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("it works 24/7")}
                </p>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("compatible with all smartphones")}
                </p>
              </li>
              <li>
                <p>
                  <span className="our-services-check"></span>
                  {t("free delivery")}
                </p>
              </li>
            </ul>

            <div className="our-social-pages-and-contact-container">
              <p>{t("contact")}</p>
              <div className="contact-details-outside-container">
                <div className="contact-details-inside-container">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#292D32"
                        strokeMiterlimit="10"
                        strokeWidth="1.5"
                        d="M21.97 18.33c0 .36-.08.73-.25 1.09-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.6.25-1.25.38-1.95.38-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98a28.75 28.75 0 01-3.28-2.8 28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.54.53 1.06 1.02 1.59 1.47.52.44.95.74 1.29.92.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"
                      ></path>
                    </svg>
                    591 - 607 - 607
                  </span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      >
                        <path d="M12 8V2l-2 2M12 2l2 2"></path>
                        <path
                          strokeMiterlimit="10"
                          d="M7 12c-4 0-4 1.79-4 4v1c0 2.76 0 5 5 5h8c4 0 5-2.24 5-5v-1c0-2.21 0-4-4-4-1 0-1.28.21-1.8.6l-1.02 1.08a2.999 2.999 0 01-4.37 0L8.8 12.6C8.28 12.21 8 12 7 12zM5 12v-2c0-2.01 0-3.67 3-3.96M19 12v-2c0-2.01 0-3.67-3-3.96"
                        ></path>
                      </g>
                    </svg>
                    {t("Info@pethub.ge")}
                  </span>
                </div>
              </div>

              <div className="social-icons-inside-container">
                <a
                  href="https://www.facebook.com/profile.php?id=61554431398309"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 20 20">
                    <g>
                      <g fill="none" stroke="none">
                        <g fill="#5e5e5e" transform="translate(-385 -7399)">
                          <g transform="translate(56 160)">
                            <path d="M335.821 7259v-9h2.733l.446-4h-3.179v-1.948c0-1.03.027-2.052 1.466-2.052h1.458v-2.86c0-.043-1.253-.14-2.52-.14-2.645 0-4.302 1.657-4.302 4.7v2.3H329v4h2.923v9h3.898z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <p className="title">{t("Facebook")}</p>
                </a>

                <a
                  href="https://www.instagram.com/pethub_georgia/"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <g>
                      <g fill="none" stroke="none">
                        <g fill="#5e5e5e" transform="translate(-340 -7439)">
                          <g transform="translate(56 160)">
                            <path d="M289.87 7279.123c-1.628.073-3.04.471-4.179 1.606-1.143 1.14-1.536 2.557-1.61 4.168-.045 1.005-.313 8.601.463 10.593a5.04 5.04 0 002.91 2.903c.634.246 1.356.412 2.416.461 8.86.401 12.145.183 13.53-3.364.246-.631.415-1.353.462-2.41.405-8.883-.066-10.809-1.61-12.351-1.225-1.222-2.666-2.054-12.382-1.606m.081 17.944c-.97-.043-1.496-.205-1.848-.341a3.255 3.255 0 01-1.888-1.883c-.591-1.514-.395-8.703-.342-9.866.051-1.14.282-2.18 1.086-2.985.995-.992 2.28-1.479 11.034-1.084 1.142.052 2.186.282 2.992 1.084.995.993 1.489 2.288 1.087 11.008-.044.968-.206 1.493-.342 1.843-.901 2.308-2.973 2.628-11.779 2.224m8.139-13.377c0 .657.534 1.19 1.194 1.19.66 0 1.195-.533 1.195-1.19a1.194 1.194 0 00-2.39 0m-9.226 5.298a5.103 5.103 0 005.11 5.097 5.103 5.103 0 005.109-5.097 5.102 5.102 0 00-5.11-5.096 5.102 5.102 0 00-5.11 5.096m1.794 0a3.313 3.313 0 013.316-3.308 3.313 3.313 0 013.317 3.308 3.313 3.313 0 01-3.317 3.31 3.313 3.313 0 01-3.316-3.31"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <p className="title">{t("Instagram")}</p>
                </a>

                <a
                  href="https://www.tiktok.com/@pethub_georgia?lang=en"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <path d="M16.656 1.029c1.637-.025 3.262-.012 4.886-.025a7.762 7.762 0 002.189 5.213l-.002-.002A8.77 8.77 0 0029 8.45l.028.002v5.036a13.327 13.327 0 01-5.331-1.247l.082.034a15.385 15.385 0 01-2.077-1.196l.052.034c-.012 3.649.012 7.298-.025 10.934a9.513 9.513 0 01-1.707 4.954l.02-.031c-1.652 2.366-4.328 3.919-7.371 4.011h-.014a9.071 9.071 0 01-5.139-1.31l.04.023C5.05 28.185 3.32 25.603 3 22.6l-.004-.041a23.163 23.163 0 01-.012-1.862c.49-4.779 4.494-8.476 9.361-8.476.547 0 1.083.047 1.604.136l-.056-.008c.025 1.849-.05 3.699-.05 5.548a4.29 4.29 0 00-5.465 2.619l-.009.03c-.133.427-.21.918-.21 1.426 0 .206.013.41.037.61l-.002-.024a4.26 4.26 0 004.382 3.586h-.009a4.198 4.198 0 003.451-1.994l.01-.018c.267-.372.45-.822.511-1.311l.001-.014c.125-2.237.075-4.461.087-6.698.012-5.036-.012-10.06.025-15.083z"></path>
                  </svg>
                  <p className="title">{t("Tiktok")}</p>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCoDx7REp8v-R1pJvvR2tE9g"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 20 20">
                    <g>
                      <g fill="none" stroke="none">
                        <g fill="#5e5e5e" transform="translate(-300 -7442)">
                          <g transform="translate(56 160)">
                            <path d="M251.988 7291.586v-5.612c1.993.938 3.536 1.843 5.36 2.82-1.505.834-3.367 1.77-5.36 2.792m11.103-8.403c-.344-.453-.93-.805-1.553-.922-1.833-.348-13.267-.349-15.099 0-.5.094-.945.32-1.328.673-1.611 1.495-1.106 9.518-.718 10.817.164.562.375.968.64 1.235.343.352.812.594 1.351.703 1.51.312 9.284.486 15.122.047a2.62 2.62 0 001.39-.712c1.49-1.49 1.388-9.962.195-11.841"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <p className="title">{t("Youtube")}</p>
                </a>
              </div>
            </div>
          </footer>

          <footer className="footer-for-mobile">
            <div
              className={
                "footer-for-mobile-title " +
                (isAboutCompanyVisalbe ? "active" : "")
              }
              onClick={() => setIsAboutCompanyVisable(!isAboutCompanyVisalbe)}
              ref={aboutCompanyRef}
            >
              <p>{t("company")}</p>

              <ul className="foot-title-dropdown">
                {/* <li>
                  <Link to="/aboutus">{t("about_us")}</Link>
                </li> */}
                <li>
                  <Link to="/contact">{t("contact")}</Link>
                </li>

                <li>
                  <Link to="/rules">{t("site rules (footer)")}</Link>
                </li>
                <li>
                  <Link to="/returnpolicy">{t("return policy")}</Link>
                </li>
                <li>
                  <Link to="/privacypolicy">{t("privacy policy")}</Link>
                </li>
                <li>
                  <Link to="">
                    {t("download")} {t("app")}
                  </Link>
                </li>
              </ul>
            </div>

            <div
              className={
                "footer-for-mobile-title " + (isQrCollarVisalbe ? "active" : "")
              }
              onClick={() => setIsQrCollarVisable(!isQrCollarVisalbe)}
              ref={qrCollarRef}
            >
              <p>{t("qrcollar")}</p>

              <ul className="foot-title-dropdown">
                <li>
                  <Link to="/order/qrcollar">{t("buy now (footer)")}</Link>
                </li>
                <li>
                  <Link to="/qrcollar">{t("how it works text1")}</Link>
                </li>
                <li>
                  <Link to="/qrcollar">{t("video instruction")}</Link>
                </li>
              </ul>
            </div>

            <div
              className={
                "footer-for-mobile-title " +
                (isOurServiceVisalbe ? "active" : "")
              }
              onClick={() => setIsOurServiceVisable(!isOurServiceVisalbe)}
              ref={ourServiceRef}
            >
              <p>{t("why our service")}</p>

              <ul>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("you have control panel")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("pet's profile update")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("activation")} / {t("deactivation")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("no monthly fee")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("no batteries needed")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("it works 24/7")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("compatible with all smartphones")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="our-services-check"></span>
                    {t("free delivery")}
                  </p>
                </li>
              </ul>
            </div>

            <div className="social-icons-inside-container-for-mobile">
              <a
                href="https://www.facebook.com/profile.php?id=61554431398309"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 20 20">
                  <g>
                    <g fill="none" stroke="none">
                      <g fill="#5e5e5e" transform="translate(-385 -7399)">
                        <g transform="translate(56 160)">
                          <path d="M335.821 7259v-9h2.733l.446-4h-3.179v-1.948c0-1.03.027-2.052 1.466-2.052h1.458v-2.86c0-.043-1.253-.14-2.52-.14-2.645 0-4.302 1.657-4.302 4.7v2.3H329v4h2.923v9h3.898z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>

              <a
                href="https://www.instagram.com/pethub_georgia/"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <g>
                    <g fill="none" stroke="none">
                      <g fill="#5e5e5e" transform="translate(-340 -7439)">
                        <g transform="translate(56 160)">
                          <path d="M289.87 7279.123c-1.628.073-3.04.471-4.179 1.606-1.143 1.14-1.536 2.557-1.61 4.168-.045 1.005-.313 8.601.463 10.593a5.04 5.04 0 002.91 2.903c.634.246 1.356.412 2.416.461 8.86.401 12.145.183 13.53-3.364.246-.631.415-1.353.462-2.41.405-8.883-.066-10.809-1.61-12.351-1.225-1.222-2.666-2.054-12.382-1.606m.081 17.944c-.97-.043-1.496-.205-1.848-.341a3.255 3.255 0 01-1.888-1.883c-.591-1.514-.395-8.703-.342-9.866.051-1.14.282-2.18 1.086-2.985.995-.992 2.28-1.479 11.034-1.084 1.142.052 2.186.282 2.992 1.084.995.993 1.489 2.288 1.087 11.008-.044.968-.206 1.493-.342 1.843-.901 2.308-2.973 2.628-11.779 2.224m8.139-13.377c0 .657.534 1.19 1.194 1.19.66 0 1.195-.533 1.195-1.19a1.194 1.194 0 00-2.39 0m-9.226 5.298a5.103 5.103 0 005.11 5.097 5.103 5.103 0 005.109-5.097 5.102 5.102 0 00-5.11-5.096 5.102 5.102 0 00-5.11 5.096m1.794 0a3.313 3.313 0 013.316-3.308 3.313 3.313 0 013.317 3.308 3.313 3.313 0 01-3.317 3.31 3.313 3.313 0 01-3.316-3.31"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>

              <a
                href="https://www.tiktok.com/@pethub_georgia?lang=en"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <path d="M16.656 1.029c1.637-.025 3.262-.012 4.886-.025a7.762 7.762 0 002.189 5.213l-.002-.002A8.77 8.77 0 0029 8.45l.028.002v5.036a13.327 13.327 0 01-5.331-1.247l.082.034a15.385 15.385 0 01-2.077-1.196l.052.034c-.012 3.649.012 7.298-.025 10.934a9.513 9.513 0 01-1.707 4.954l.02-.031c-1.652 2.366-4.328 3.919-7.371 4.011h-.014a9.071 9.071 0 01-5.139-1.31l.04.023C5.05 28.185 3.32 25.603 3 22.6l-.004-.041a23.163 23.163 0 01-.012-1.862c.49-4.779 4.494-8.476 9.361-8.476.547 0 1.083.047 1.604.136l-.056-.008c.025 1.849-.05 3.699-.05 5.548a4.29 4.29 0 00-5.465 2.619l-.009.03c-.133.427-.21.918-.21 1.426 0 .206.013.41.037.61l-.002-.024a4.26 4.26 0 004.382 3.586h-.009a4.198 4.198 0 003.451-1.994l.01-.018c.267-.372.45-.822.511-1.311l.001-.014c.125-2.237.075-4.461.087-6.698.012-5.036-.012-10.06.025-15.083z"></path>
                </svg>
              </a>

              <a
                href="https://www.youtube.com/channel/UCoDx7REp8v-R1pJvvR2tE9g"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 20 20">
                  <g>
                    <g fill="none" stroke="none">
                      <g fill="#5e5e5e" transform="translate(-300 -7442)">
                        <g transform="translate(56 160)">
                          <path d="M251.988 7291.586v-5.612c1.993.938 3.536 1.843 5.36 2.82-1.505.834-3.367 1.77-5.36 2.792m11.103-8.403c-.344-.453-.93-.805-1.553-.922-1.833-.348-13.267-.349-15.099 0-.5.094-.945.32-1.328.673-1.611 1.495-1.106 9.518-.718 10.817.164.562.375.968.64 1.235.343.352.812.594 1.351.703 1.51.312 9.284.486 15.122.047a2.62 2.62 0 001.39-.712c1.49-1.49 1.388-9.962.195-11.841"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </footer>

          <div className="download-app-and-user-counter">
            <div className="download-app">
              <a href="">
                <img src="/svg/google.svg" alt="" />
              </a>
              <a href="">
                <img src="/svg/apple.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <p>{t("copyright")} © 2024 Pethub.Ge</p>
      </div>
    </>
  );
};

export default Footrer;
