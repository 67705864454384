import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axiosInstance from "../../axiosApi/api";

const VerifyAccount = () => {
  const { t } = useTranslation();
  const [verifyStatus, setVerifyStatus] = useState(null);
  const { uid64, token } = useParams();

  const verifyToken = async () => {
    try {
      await axiosInstance.post(`/users/activate/${uid64}/${token}/`);
      setVerifyStatus(true);
    } catch {
      setVerifyStatus(false);
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);

  const renderVerificationStatus = () => {
    if (verifyStatus === false) {
      return (
        <div className="parent-container-short">
          <div className="verify-account">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="verify-message-container">
              <p className="verified-header-text">
                {t("account_activation_success_header")}
              </p>
              <p className="verified-message-text">
                {t("account_activation_success")}
              </p>

              <Link className="verified-account-go-back" to="../">
                {t("home")}
              </Link>
            </span>
          </div>
        </div>
      );
    } else if (verifyStatus === true) {
      return (
        <div className="parent-container-short">
          <div className="verify-account failed">
            <svg
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span className="verify-message-container">
              <p className="verify-failed-header-text">
                {t("account_activation_failed_header")}
              </p>

              <p className="verify-failed-message-text">
                {t("account_activation_failed")}
              </p>

              <Link className="verify-failed-go-back" to="../">
                {t("home")}
              </Link>
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="spinner">
          <img src="./public/img/loading.gif" alt="loading" />
        </div>
      );
    }
  };

  return <div>{renderVerificationStatus()}</div>;
};

export default VerifyAccount;
